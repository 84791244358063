@use "ui/theme";

@import "@angular/cdk/overlay-prebuilt.css";

@font-face {
  font-family: theme.$font-family;
  src: theme.$font-family-url;
  font-display: swap;
  font-weight: 400 700;
}

html {
  font-family: theme.$font-family, sans-serif;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

body {
  @include theme.fx-background();
  color: theme.$foreground-color;
}

html,
body {
  height: 100vh;
  height: 100dvh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: theme.$outline-color;
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

hr {
  background-color: theme.$outline-color;
  height: 1px;
  border: none;
}

a.link-text {
  color: theme.$primary-color;
  text-decoration: none;
}

.cdk-menu {
  background-color: theme.$background-color;
  min-width: 10rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  box-shadow: theme.$box-shadow;
  border: 1px solid theme.$outline-color;
  border-radius: 0.5rem;
  gap: 1rem;

  .cdk-menu-item {
    cursor: pointer;
    appearance: none;
    font-size: 1rem;
    color: theme.$subtle-color;
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: start;
    text-decoration: none;
    text-align: start;

    &:hover {
      color: theme.$foreground-color;
    }
  }
}
