$primary-color: #1daa91;
$primary-transparent-color: rgba(29, 170, 145, 0.2);
$on-primary-color: #0b0c10;
$outline-color: rgba(194, 208, 228, 0.2);
$background-color: #101116;
$foreground-color: #f6f8ff;
$neutral-color: #868686;
$surface-color: rgba(5, 5, 8, 0.3);
$inactive-color: rgba(255, 255, 255, 0.5);
$error-color: #e31414;
$subtle-color: #c2d0e4;
$warning-color: #c4c728;
$subtitle-color: rgba(194, 208, 228, 0.5);
$dialog-background-color: #000000;
