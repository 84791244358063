@use "colors";
@use "gradients";

@mixin shimmer() {
  color: colors.$foreground-color;
  background-image: linear-gradient(
    70deg,
    transparent,
    gradients.$gradient-color-1 20%,
    gradients.$gradient-color-2 30%,
    gradients.$gradient-color-3 70%,
    gradients.$gradient-color-4 80%,
    transparent
  );

  background-size: 200% 100%;
  background-repeat: no-repeat;
  animation-name: shimmer;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  background-blend-mode: color-burn;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}
