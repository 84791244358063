@use "colors";
@use "gradients";

$slider-thumb-size: 2rem;
$slider-track-height: 0.5rem;

@mixin slider() {
  appearance: none;
  width: 100%;
  height: $slider-thumb-size;
  cursor: ew-resize;
  background: transparent;

  &:focus {
    outline: none;
  }
}

@mixin slider-thumb() {
  appearance: none;
  background: colors.$primary-color;
  height: $slider-thumb-size;
  width: $slider-thumb-size;
  border-radius: 50%;
  margin-top: calc(($slider-thumb-size - $slider-track-height) / 2 * -1);
}

@mixin slider-track() {
  height: $slider-track-height;
  background-image: gradients.fx-gradient(),
    linear-gradient(to right, colors.$surface-color, colors.$surface-color 100%);
  background-size:
    var(--value) 100%,
    100% 100%;
  background-repeat: no-repeat;
  border-radius: calc($slider-track-height / 2);
}

input.slider[type="range"] {
  @include slider();

  &::-webkit-slider-thumb {
    @include slider-thumb();
  }

  &::-webkit-slider-runnable-track {
    @include slider-track();
  }

  &::-moz-range-thumb {
    @include slider-thumb();
  }

  &::-ms-thumb {
    @include slider-thumb();
  }

  &::-ms-track {
    @include slider-track();
    width: 100%;
  }
}
