@use "colors";
@use "gradients";

@mixin text-headline {
  font-size: 2rem;
  line-height: 1.5rem;
  font-weight: 400;
}

@mixin text-title {
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 400;
}

@mixin text-subtitle {
  font-size: 1rem;
  line-height: 2;
  font-weight: 400;
}

@mixin text-label {
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 500;
}

.text-headline {
  @include text-headline;
}

.text-title {
  @include text-title;
}

.text-subtitle {
  @include text-subtitle;
}

.text-label {
  @include text-label;
}

.text-gradient {
  @include gradients.fx-gradient-text();
}

.text-primary {
  color: colors.$primary-color;
}

.text-error {
  color: colors.$error-color;
}
