@use "colors";
@use "gradients";

progress.progress[value] {
  width: 100%;
  display: block;
  appearance: none;
  border-radius: 0.25rem;
  height: 0.5rem;
  overflow: hidden;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;

  &::-webkit-progress-bar {
    background-color: colors.$surface-color;
  }

  &::-webkit-progress-value {
    background: gradients.fx-gradient();
  }
}
