@use "colors";
@use "shadows";

@mixin panel {
  background-color: colors.$surface-color;
  border-radius: 0.75rem;
  border: 1px solid colors.$outline-color;
  padding: 2rem;
}

.panel {
  @include panel;
}
