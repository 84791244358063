@mixin fx-background() {
  background-repeat: no-repeat;
  background-color: #0b0c10;
  background-image: radial-gradient(
      868px 500px at 54px 850px,
      rgb(20, 31, 55),
      transparent
    ),
    radial-gradient(
      868px 500px at 892px 288px,
      rgba(194, 208, 228, 0.05),
      transparent
    ),
    radial-gradient(868px 500px at 420px 0, rgba(20, 31, 55, 0.35), transparent),
    radial-gradient(
      868px 500px at 1680px 0,
      rgba(10, 193, 183, 0.15),
      transparent
    );
  background-attachment: fixed;
  background-size: 100% 100%;
  background-blend-mode: normal;
}
