@use "colors";

.message {
  display: block;
  text-align: start;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  padding: 1rem;
  line-height: 1.25;
  font-size: 1rem;

  &.error {
    color: colors.$error-color;
    border-color: colors.$error-color;
    background-color: hsl(from colors.$error-color h s l / 0.1);
  }

  &.warning {
    color: colors.$warning-color;
    border-color: colors.$warning-color;
    background-color: hsl(from colors.$warning-color h s l / 0.1);
  }

  &.success {
    color: colors.$primary-color;
    border-color: colors.$primary-color;
    background-color: hsl(from colors.$primary-color h s l / 0.1);
  }
}
