$gradient-color-1: rgba(44, 217, 255, 1);
$gradient-stop-1: 0%;

$gradient-color-2: rgba(126, 255, 178, 0.8);
$gradient-stop-2: 18%;

$gradient-color-3: rgba(244, 255, 122, 0.8);
$gradient-stop-3: 84%;

$gradient-color-4: rgba(255, 216, 14, 1);
$gradient-stop-4: 100%;

$gradient-direction: 88deg;

@function fx-gradient($direction: $gradient-direction) {
  @return linear-gradient(
    $direction,
    $gradient-color-1 $gradient-stop-1,
    $gradient-color-2 $gradient-stop-2,
    $gradient-color-3 $gradient-stop-3,
    $gradient-color-4 $gradient-stop-4
  );
}

@function fx-gradient-inverse() {
  @return fx-gradient($gradient-direction + 180deg);
}

@mixin fx-gradient-text($direction: $gradient-direction) {
  background: fx-gradient($direction);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: fit-content;
}

@mixin fx-gradient-inverse-text($direction: $gradient-direction) {
  background: linear-gradient(
    $direction,
    $gradient-color-4 $gradient-stop-1,
    $gradient-color-3 $gradient-stop-2,
    $gradient-color-2 $gradient-stop-3,
    $gradient-color-1 $gradient-stop-4
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: fit-content;
}

@mixin fx-gradient-background(
  $background-color,
  $direction: $gradient-direction
) {
  background:
    linear-gradient($background-color, $background-color) padding-box,
    fx-gradient() border-box;
  border: solid 1px transparent;
}
