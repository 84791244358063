@use "colors";

.table-container {
  max-width: 100%;
  overflow: auto;
  overflow-y: hidden;
}

table.table {
  width: 100%;
  border-collapse: collapse;

  tr {
    border: 1px solid colors.$outline-color;
  }

  td,
  th {
    padding: 1.5rem;
  }

  th {
    text-align: left;
    white-space: nowrap;
  }
}
