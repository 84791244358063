@use "colors";
@use "gradients";
@use "text";

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.form .field {
  text-align: start;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  gap: 0.5rem;
}

.field .label {
  display: inline-block;
  @include text.text-label;
}

input.input,
select.input,
textarea.input {
  appearance: none;
  font-family: inherit;
  border: 2px solid colors.$foreground-color;
  padding: 0 1.5rem;
  font-size: 1rem;
  background-color: colors.$foreground-color;
  border-radius: 0.5rem;
  overflow: hidden;
  height: 3rem;
  transition:
    border-color 300ms,
    background-color 300ms;

  &.ng-valid {
    border-color: colors.$primary-color;
    background-color: hsl(from colors.$primary-color h s 90%);
  }

  &.ng-invalid.ng-touched {
    border-color: colors.$error-color;
    background-color: hsl(from colors.$error-color h s 90%);
  }

  &:disabled {
    background-color: colors.$inactive-color;
    border-color: colors.$outline-color;
  }
}

textarea.input {
  padding: 1rem 1.5rem;
  resize: vertical;
}

input[type="checkbox"].checkbox {
  appearance: none;
  height: 1rem;
  width: 1rem;
  border-radius: 0.25rem;
  background-color: colors.$subtle-color;
  margin: 0;
  box-sizing: border-box;
  font-size: 1rem;
  border: 1px solid colors.$outline-color;
  position: relative;
  margin-right: 0.5rem;

  &::after {
    content: "";
    position: absolute;
    inset: 2px;
    background-image: url("/images/check.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: scale(0);
    transition: transform 0.2s;
    transform-origin: center;
  }

  &:checked {
    background-color: colors.$primary-color;

    &::after {
      transform: scale(1);
    }
  }
}
